// @ts-check
import i18next from "i18next";
import HttpApi from "i18next-http-backend";
import {initReactI18next} from "react-i18next";

const DEFAULT_NAMESPACE = "translation";
const DEFAULT_LANGUAGE = "de";
const DEFAULT_SUPPORTED_LANGUAGES = ["de", "en"];
const LOAD_URL = "/locales/{{lng}}/{{ns}}";
const DEFAULT_SUSPENSE = false;

/**
 * init translations
 *
 * load default translations and translations for a namespace (tenant)
 *
 * @param {String} namespace                    the id of the tenant
 * @param {String} language                     the language, defaults to "de"
 * @param {Array<String>} supportedLanguages    list of supported languages
 * @param {Boolean} suspense                    suspense
 * @return {i18n}
 */
function initTranslations(
    namespace = DEFAULT_NAMESPACE,
    language = DEFAULT_LANGUAGE,
    supportedLanguages = DEFAULT_SUPPORTED_LANGUAGES,
    suspense = DEFAULT_SUSPENSE
) {
    i18next
        .use(HttpApi)
        .use(initReactI18next)
        .init({
            lng: language || DEFAULT_NAMESPACE,
            fallbackLng: language || DEFAULT_NAMESPACE,
            supportedLngs: supportedLanguages || DEFAULT_SUPPORTED_LANGUAGES,
            defaultNS: namespace || DEFAULT_NAMESPACE,
            fallbackNS: DEFAULT_NAMESPACE,
            interpolation: {
                escapeValue: false
            },
            preload: [language || DEFAULT_LANGUAGE],
            // options for react-i18next
            // https://react.i18next.com/latest/migrating-v9-to-v10#migration
            react: {
                // suspend render until translations loaded
                useSuspense: suspense
            },
            backend: {
                loadPath: LOAD_URL
            }
        });
    return i18next;
}

/**
 * lazy load translations
 * @param {String} namespace    the namespace name, e.g. tenant name
 * @return {void}
 */
function loadTranslations(namespace = "translation") {
    i18next.loadNamespaces(namespace).then(() => {
        i18next.setDefaultNamespace(namespace);
    });
}

export default i18next;
export {initTranslations, loadTranslations};
