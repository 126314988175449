/**
 * @fileOverview user menu, e.g. to display as a secondary menu on the left
 */
export default [
    {
        name: "settings",
        translationKey: "UserMenu.settings",
        component: "SettingsMenuIcon",
        icon: "Settings",
        route: "/admin",
        pages: [
            {
                name: "user-management",
                route: "/admin/users",
                translationKey: "MainMenu.users"
            },
            {
                name: "discipline-management",
                route: "/admin/disciplines",
                translationKey: "MainMenu.disciplines"
            },
            {
                name: "surgery-assignment",
                route: "/admin/surgery-assignment",
                translationKey: "MainMenu.surgeries",
                hasBadge: true
            }
        ]
    }
];
