// @ts-check
import {STATUS_KEY} from "./op_status";

/**
 * prefix for api routes of services
 */
export const resourceHistoryPrefix = "/resource-history";
export const orchestratorPrefix = "/orchestrator";

/**
 * @typedef {Object} Param
 * @property {"Appointment"|"ServiceRequest"|"manualChanges"} resouce the name of the resource
 * @property {String} path the name of the property of the resource the value can be also set
 */

/**
 * @typedef {Object} ResourceHistoryParams
 * @property {Param} STATUS_ON_HOLD Changes in which the status was set to cancelled
 * @property {Param} REASON_FOR_CHANGES Changes on the reasonForChanges in the manualChanges
 * @property {Number} MAX_NUMBER_OF_IDS The max number of ids to be set in a call
 */

/**
 * api params for the history service
 *
 * @typedef {ResourceHistoryParams}
 */
export const RESOURCE_HISTORY = {
    // STATUS_ON_HOLD and REASON_FOR_CHANGES are used in the op backlog table
    STATUS_ON_HOLD: {
        resource: "UnpublishedAppointment",
        path: "status",
        changedValue: "cancelled"
    },
    REASON_FOR_CHANGES: {
        resource: "ManualChange",
        path: "reasonForChange"
    },
    // The following items are Used in the change history in the info layer
    PARTICIPANT: {
        hasIds: true,
        resource: "Appointment",
        path: "participant,Practitioner"
    },
    LOCATION: {
        hasIds: true,
        resource: "Appointment",
        path: "participant,Location"
    },
    START: {
        hasIds: true,
        resource: "Appointment",
        path: "start" // same as next_internalTimestamps.duraRoomLockPre.dtStart
    },
    PLANNED: {
        hasIds: true,
        resource: "Appointment",
        path: "status",
        changedValue: "booked"
    },
    CANCELLED: {
        resource: "Appointment",
        path: "status",
        changedValue: "cancelled"
    },
    REVOKED: {
        resource: "ServiceRequest",
        path: "status",
        changedValue: "revoked"
    },
    // The max. number of the ids that can be set in the URL param
    MAX_NUMBER_OF_IDS: 50
};

/**
 * @typedef {Object} IdentityServerParams
 * @property {Param} ROLES_MED_CLEARANCE The role of the users who are shown in the pulldown of the medically cleared
 */
export const IDENTITY_SERVER = {
    ROLES_MED_CLEARANCE: ["medical-op-manager"]
};

export const ORCHESTRATOR = {
    STATUS_MAPPING_MANUAL_CHANGE: {
        [STATUS_KEY.PLANNED]: "pending",
        [STATUS_KEY.ON_HOLD]: "cancelled"
    }
};
