/**
 * @fileOverview primary main menu, e.g. to display on the left side
 *
 * Please note: all routes need to start with a "/"
 */

export default [
    {
        name: "schedule",
        translationKey: "MainMenu.schedule",
        icon: "CalendarToday",
        route: "/schedule",
        pages: [{name: "dayview", route: "/schedule/dayview", translationKey: "MainMenu.dayview"}]
    },
    {
        name: "op-management",
        translationKey: "MainMenu.op-management",
        icon: "Assignment",
        route: "/op-management",
        pages: [
            {
                name: "schedule-management",
                route: "/op-management/schedule-management",
                translationKey: "MainMenu.schedule-management"
            },
            {
                name: "timeslot-planner",
                route: "/op-management/timeslot-planner",
                translationKey: "MainMenu.timeslot-planner"
            },
            {
                name: "availability-planner",
                route: "/op-management/availability-planner",
                translationKey: "MainMenu.availability-planner"
            },
            {
                name: "room-planner",
                route: "/op-management/room-planner",
                translationKey: "MainMenu.room-planner"
            }
        ]
    },
    {
        name: "notifications",
        translationKey: "UserMenu.notifications",
        icon: "NotificationsIcon",
        component: "UserNotificationsBadgeContainer",
        route: "/notifications",
        pages: [{name: "myNotifications", route: "/notifications", translationKey: "UserMenu.notifications"}]
    },
    {
        name: "account",
        translationKey: "MainMenu.account",
        route: "/account",
        pages: [{name: "account", route: "/account", translationKey: "MainMenu.account"}]
    }
];
